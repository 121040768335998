<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/">首页</router-link>><router-link to="/info">个人中心</router-link>><a href="javascript:;">发票管理</a>
        </div>
      </div>
      <div class="personal per_invoice pt30 m1440 flex-box flex-col-start">
        <div class="left_nav fs18">
          <router-link to="/info">基本信息</router-link>
          <router-link to="/certificate">证书抬头</router-link>
          <router-link to="/maintain_list">开票信息</router-link>
          <router-link to="/address_list">收件地址</router-link>
          <router-link to="/invoices_status" class="active">发票管理</router-link>
          <router-link to="/contract_management">合同管理</router-link>
          <router-link to="/change_password">修改密码</router-link>
          <router-link to="/change_phone">修改手机号</router-link>
          <a @click="cancellation()" class="cursor" href="javascript:;">删除账户</a>
        </div>
        <div class="right_box flex-grow-1">
          <div class="fs18 fwb pb30">申请开票
            <a href="javascript:;" v-if="maintain_list.length >1" class="add_certificate fs16 ml0" @click="closePop3()">选择其它开票信息</a>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">订单编号：</div>
            <div class="fs16">{{ order_no }}</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">发票形式：</div>
            <div class="type_box fs16" :class="tabIndex == 0 ? 'active' : ''">电子</div>
            <!-- <div class="type_box fs16" @click="testTabClick(0)" :class="tabIndex == 0 ? 'active' : ''">电子</div> -->
            <!-- <div class="type_box fs16" @click="testTabClick(1)" :class="tabIndex == 1 ? 'active' : ''">纸质</div> -->
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">发票类型：</div>
            <div class="type_box fs16" @click="Click(0)" :class="s_index == 0 ? 'active' : ''">普票</div>
            <div class="type_box fs16" @click="Click(1)" :class="s_index == 1 ? 'active' : ''">专票</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">发票抬头：</div>
            <div class="fs16">{{ invoice.title }}</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">纳税识别号：</div>
            <div class="fs16">{{ invoice.texNo }}</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">开户行：</div>
            <div class="fs16">{{ invoice.bankName }}</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">银行账号：</div>
            <div class="fs16">{{ invoice.account }}</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">详细地址：</div>
            <div class="fs16">{{ invoice.address }}</div>
          </div>
          <div class="pb30 flex-box">
            <div class="invoice_title fs16 col9 mr10">联系电话：</div>
            <div class="fs16">{{ invoice.tel }}</div>
          </div>
          <div v-if="tabIndex==1">
            <div class="fs18 fwb pb20 pt40 mt10 border">收件信息</div>
          <div class="name_box" v-if="address_list.length >0">
            <div class="flex-box flex-between pb20">
              <div class="fs16 col10 flex-box">
                <span class="invoice_title col9">收件人：</span><span>{{ address.receiverName }}</span>
              </div>
              <div class="target" v-if="address.status == '1'">默认</div>
            </div>
            <div class="fs16 col10 pb20 flex-box">
              <span class="invoice_title col9">详细地址：</span><span>{{ address.region }}{{ address.addressDetail }}</span>
            </div>
            <div class="flex-box flex-between">
              <div class="fs16 col10 flex-box">
                <span class="invoice_title col9">联系电话：</span><span>{{ address.receiverTel }}</span>
              </div>
              <div class="fs16 normal use_other" @click="closePop2">使用其他地址</div>
            </div>
          </div>
          </div>

          <div class="save" @click.stop="submit">提交</div>
        </div>
      </div>
    </div>

    <!-- 新增收件地址 -->
    <Popup :visible="popup" :confirmText="false" :cancleText="false" @close="closePop">
      <div class="name_pop">
        <div class="flex-box name_title flex-between">
          <div class="fs20">{{ address_type == 0 ? '新增' : '编辑' }}收件地址</div>
          <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="closePop()" alt="" />
        </div>
        <div class="cirticle_info">
          <div class="must fs16 col9 info_title">收件人</div>
          <input type="text" class="input1" v-model="current.receiverName" placeholder="请输入收件人" />
          <div class="must fs16 col9 info_title">所在地区</div>
          <el-cascader size="large" class="outer flex-box flex-between" :options="options" v-model="selectedOptions"
            @change="handleChange" :placeholder="current.region ? current.region : '请选择'">
          </el-cascader>
          <div class="must fs16 col9 info_title">详细地址</div>
          <input type="text" class="input1" v-model="current.addressDetail" placeholder="请填写详细地址" />
          <div class="must fs16 col9 info_title">手机号码</div>
          <input type="number" class="input1" v-model="current.receiverTel" placeholder="请填写手机号码" />
          <div class="flex-box">
            <div class="fs16 col9 mr30">设为默认收件地址</div>
            <div class="switch" :class="current.status == '1' ? 'on' : ''" @click="setDefault()"></div>
          </div>
          <div class="save" @click="add_address">保存</div>
        </div>
      </div>
    </Popup>
    <!-- 选择收货地址 -->
    <Popup :visible="infoPop" :confirmText="false" :cancleText="false" @close="closePop2">
      <div class="name_pop">
        <div class="flex-box name_title flex-between">
          <div class="fs20">选择收货地址</div>
          <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="closePop2()" alt="" />
        </div>
          <ul class="cirticle_list">
          <li class="flex-box" v-for="(v, k) in address_list" :key="k" @click="choose_address(k)">
            <div class="default" v-if="v.status == 1">默认</div>
            <div class="agreePop_box" :class="address.id == v.id ? 'on' : ''"></div>
            <div class="cirticle_box">
              <div class="pb20">
                <span class="fs16 col9">收件人</span>
                <span class="fs16 col10">{{ v.receiverName }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">所在地区：</span>
                <span class="fs16 col10">{{ v.region }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">详细地址：</span>
                <span class="fs16 col10">{{ v.addressDetail }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">手机号码：</span>
                <span class="fs16 col10">{{ v.receiverTel }}</span>
              </div>
            </div>
          </li>
        </ul>
         <div v-if="address_list.length >0">
           <Pagination :sumCount="total" :perCount="pageSize" @pageTurn="pageTurn"></Pagination>
         </div>
       <div class="flex-box flex-between sure_add">
          <div class="sure fs16" v-if="address_list.length >0" @click="closePop2()">确定</div>
          <div class="add fs16" @click="closePop()">新增地址</div>
        </div>
      </div>
    </Popup>

        
 <!-- 选择开票信息 -->
 <Popup :visible="infoPop2" :confirmText="false" :cancleText="false" @close="closePop3">
      <div class="name_pop">
        <div class="flex-box name_title flex-between">
          <div class="fs20">选择开票信息</div>
          <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="closePop3()" alt="" />
        </div>
          <ul class="cirticle_list">
          <li class="flex-box" v-for="(v, k) in maintain_list" :key="k" @click="choose_maintain(k)">
            <div class="default" v-if="v.defaultFlag == 1">默认</div>
            <div class="agreePop_box" :class="k == maintain_index ? 'on' : ''"></div>
            <div class="cirticle_box">
              <div class="pb20">
                <span class="fs16 col9">发票抬头：</span>
                <span class="fs16 col10">{{ v.title }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">纳税识别号：</span>
                <span class="fs16 col10">{{ v.texNo }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">详细地址：</span>
                <span class="fs16 col10">{{ v.address }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">联系电话：</span>
                <span class="fs16 col10">{{ v.tel }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">开户行：</span>
                <span class="fs16 col10">{{ v.bankName }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">银行账号：</span>
                <span class="fs16 col10">{{ v.account }}</span>
              </div>
            </div>
          </li>
        </ul>
         <div v-if="maintain_list.length >0">
           <Pagination :sumCount="total2" :perCount="pageSize" @pageTurn="pageTurn2"></Pagination>
         </div>
         <div class="flex-box flex-between sure_add">
          <div class="sure fs16" v-if="maintain_list.length >0" @click="sure_maintain()">确定</div>
        </div>
      </div>
    </Popup>

    <Footer></Footer>
  </div>
</template>

<script>
import Pagination from "@/components/pagination.vue";
import { regionData, CodeToText } from "element-china-area-data";
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
import cookie from "vue-cookies";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      popup: false, //添加地址弹窗
      infoPop: false, //选择收货地址
      infoPop2:false, //发票信息
      tabIndex: 0,
      Index: 0, // 发票类型
      s_index: 0, // 发票种类
      order_id: 0,
      order_no: '',
      address: {},
      common: {}, //普票信息
      special: {}, //专票信息
      invoice: {
        title: '',
        texNo: '',
        bankName: '',
        account: '',
      },
      options: regionData,
      selectedOptions: [],
      address_list: [],
      current: {
        receiverName: '',
        receiverTel: '',
        addressDetail: '',
        status: '0',
        region: ''
      },
      address_type:0,
      pageSize: 5, //分页大小
      total: 0, //分页总数
      pageNum: 1, // 分页记录数

      maintain_list:[],
      total2: 0, //分页总数
      pageNum2: 1, // 分页记录数
      maintain_index:-1, //发票下标
    };
  },

  created() {
    let that = this
    that.order_id = that.$route.query.id
    that.order_no = that.$route.query.no
    var order_id = unescape(that.$route.query.id)
    that.order_id = order_id.replace('环保桥', '')
    var no = unescape(that.$route.query.no)
    that.order_no = no.replace('环保桥', '')
    that.get_maintain()
    // that.get_invoice()
    that.get_address()
  },
  methods: {
    //添加或编辑地址
    add_address() {
      let that = this
      let type = that.address_type
      let current = that.current
      if (!current.receiverName) {
        that.$message.error('请输入姓名');
        return;
      }
      if (!current.receiverTel) {
        that.$message.error('请输入手机号');
        return;
      }
      if (!current.region) {
        that.$message.error('请选择城市');
        return;
      }
      if (!current.addressDetail) {
        that.$message.error('请输入详细地址');
        return;
      }
      let list = that.address_list
      let e_index = that.e_index
      if (type == 0) {
      } else {
        current.id = list[e_index].id
      }
      that.$api.addressEdit(current).then((res) => {
        if (res.resCode == 0) {
          that.$message.success('提交成功');
          setTimeout(function () {
            that.popup = !that.popup;
              that.current =  {
              receiverName: '',
              receiverTel: '',
              addressDetail: '',
              status: '0',
              region: ''
            },
            that.get_address()
          }, 800)
        } else {
          that.$message.error(res.resMsg);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    //选择省市区
    handleChange() {
      var loc = "";
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]] + "-";
      }
      let city = loc.split("-")[1]
      if (city == '市辖区') {
        this.current.region = loc.split("-")[0] + loc.split("-")[2]
      } else {
        this.current.region = loc.split("-")[0] + loc.split("-")[1] + loc.split("-")[2]
      }
    },
    // 默认抬头
    setDefault() {
      let _that = this;
      let status = this.current.status
      if (status == '1') {
        var s_status = '0'
      } else {
        var s_status = '1'
      }
      _that.current.status = s_status;
    },

    // 选择其他的地址
    choose_address(index) {
      let list = this.address_list
      this.address = list[index];
    },

    choose_maintain(index){
      this.maintain_index = index
    },

    submit() {
      let that = this
      let Index = that.tabIndex
      let address = that.address
      let invoice = that.invoice
      let obj = {
        tradeId: that.order_id,
        invoiceId: invoice.id,
      }
      if(!invoice || !invoice.title || !invoice.texNo || !invoice.bankName|| !invoice.account){
           that.$message.error('请先完善开票信息');
					 return;
			}
      // 发票类型
      if (Index == 0) {
        obj.materialType = 'ELECTRIC'
      } else {
        obj.materialType = 'PAPER'
      }
      // 发票种类
      let s_index = that.s_index
      if (s_index == 0) {
        obj.invoiceType = 'COMMON'
      } else {
        obj.invoiceType = 'SPECIAL'
      }
      if(Index == 1){
        let address_list = that.address_list
        if(address_list.length <=0){
           that.$message.error('请添加地址');
           that.$router.push("/address_list");
           return;
        }
        var addressId = address.id
        if(!addressId){
          that.$message.error('请选择收货地址');
           return;
        }
        obj.addressId = addressId
      }
      obj.invoiceStatus = '1'
      that.$api.addTradeInvoice(obj,'application/json').then((res) => {
        if(res.resCode==0){
          that.$message.success('提交成功,待审核')
        setTimeout(function () {
          that.$router.push("/invoices_status");
        }, 800)
        }else{
          that.$message.error(res.resMsg)
        }
       
      }).catch((err) => {
        console.log(err);
      });
    },

    closePop() {
      this.popup = !this.popup;
      this.infoPop = false;
    },
    closePop2() {
      this.infoPop = !this.infoPop;
    },

    //选择发票
    closePop3(){
      this.infoPop2 = !this.infoPop2;
    },
    sure_maintain(){
      let list = this.maintain_list
      let index = this.maintain_index
      if(index <0){
        this.$message.error('请选择发票');
        return;
      }
      this.maintain_index = -1;
      this.invoice = list[index];
      this.infoPop2 = !this.infoPop2;
    },
   

    //发票形式
    testTabClick(index) {
      this.tabIndex = index
    },
    //类型
    Click(index) {
      this.s_index = index
    },

    // //获取发票
    // get_invoice() {
    //   let that = this;
    //   this.$api.userInvoices({}).then((res) => {
    //     let list = res.root
    //     if (list.length > 0) {
    //       that.invoice = list[0]
    //     }
    //   }).catch((err) => {
    //     console.log(err);
    //   });
    // },

    get_address() {
      var that = this;
      let page = that.pageNum;
      let s_param = {
        deviceType: "1",
        sortProp: "status",
        sortType: "DESC",
        page: page,
        limit: that.pageSize,
      };
      that.$api.apiGetAddress(s_param).then((data_res) => {
        console.log(data_res);
        if (data_res.resCode == 0) {
          var totalCount = data_res.totalCount;
          if (JSON.stringify(data_res.root) === "{}" || data_res.root.length <= 0) {
            that.isShowNavBarLoading = true;
            that.total = totalCount;
            that.address_list = data_res.root;
          } else {
            // 判断 是否 加载完毕
            var is_data = true;
            var show_total = that.pageNum * that.pageSize;
            if (totalCount > show_total) {
              //当只有一页的时候展示
              is_data = true;
            } else {
              is_data = false;
            }
            let s_data = data_res.root[0]
            var tel = s_data.receiverTel.substring(0, 3) + "****" + s_data.receiverTel.substr(s_data
              .receiverTel.length - 4);
            s_data.receiverTel_text = tel
            that.address = s_data
            that.total = totalCount;
            that.address_list = data_res.root;
            that.isShowNavBarLoading = is_data;
          }
        } else {
          that.$message.error(data_res.msg);
        }
      });
    },


    // 分页
    pageTurn(page) {
      this.pageNum = page + 1;
      this.get_address();
    },


   // 发票分页
   pageTurn2(page) {
      this.pageNum2 = page + 1;
      this.get_maintain();
    },
    // 获取抬头
    get_maintain() {
      var that = this;
      let page = that.pageNum2;
      var s_param = {
        page: page,
        limit: that.pageSize,
        sortProp:'defaultFlag',
        // sortType:'DESC'
      }
      this.$api.userInvoices(s_param).then((data_res) => {
        //console.log(data_res);
        if (data_res.resCode == 0) {
          if (JSON.stringify(data_res.root) === "{}" || data_res.root.length <= 0) {
            that.isShowNavBarLoading = true;
          } else {
            // 判断 是否 加载完毕
            var is_data = true;
            var totalCount = data_res.totalCount;
            var show_total = that.pageNum * that.pageSize;
            if (totalCount > show_total) {
              //当只有一页的时候展示
              is_data = true;
            } else {
              is_data = false;
            }
            that.invoice = data_res.root[0]
            that.total2 = totalCount;
            that.maintain_list = data_res.root;
            that.isShowNavBarLoading = is_data;
          }
        } else {
          that.isShowNavBarLoading == true
          that.$message.error(data_res.msg);
        }
      });
    },


     //删除
     cancellation() {
      let that = this;
      MessageBox.confirm("是否确定删除当前账户?", "提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            let userinfo = cookie.get("huanbaoqiao_userinfo");
            that.$api.logOffAccount({ userId: userinfo.id}).then((res) => {
              if (res.resCode == 0) {
                that.$message.success('删除成功');
                cookie.remove("huanbaoqiao_token");
                cookie.remove("huanbaoqiao_userinfo");
                setTimeout(function () {
                  that.$router.push("/");
                }, 800)
              } else {
                that.$message.error(res.resMsg);
              }
            });

          }
        })
        .catch((err) => {
          //    console.log(err);
        });
    },

  }

}

</script>

